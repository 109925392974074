footer {
  background: #383838;
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: white;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.permalinks li:hover {
    scale: 1.1;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
/*  background: transparent;*/
  color: white;
  border-color: var(--color-bg);
  scale: 1.2;
}

/* ============ SMALL DEVICES =========== */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 5rem;
  }

 .footer__socials a:hover {
      scale: 1;
  }

 .permalinks li:hover {
      scale: 1;
  }
}
