.container.conatct__container {
  width: 58%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12%;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid grey;
  resize: none;
  color: var(--color-white);
}

/* ============ MEDIUM DEVICES =========== */

@media screen and (max-width: 1024px) {
  .container.conatct__container {
    gap: 2rem;
  }
}

/* ============ SMALL DEVICES =========== */

@media screen and (max-width: 600px) {
  .container.conatct__container {
    width: var(--container-width-sm);
  }
}
