.about__container {
  display: grid;
  gap: 15%;
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 35px;
  background: #262626;
  box-shadow:  7px 7px 14px #202020,
  -7px -7px 14px #2c2c2c;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem !important;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

section {
    text-align: center;
}

/* ============ MEDIUM DEVICES =========== */

@media screen and (max-width: 1024px) {
  .about__container {
    gap: 0;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ============ SMALL DEVICES =========== */

@media screen and (max-width: 600px) {
  .about__cards {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}
