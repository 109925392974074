header {
  height: 80vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__conatiner {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======= CTA ======= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ======= HEADER SOCIALS ======= */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ======= ME ======== */

.me {
  background-color: var(--color-primary);
  width: 22rem;
  height: 28rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 2rem;
  overflow: hidden;
  /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/* ======== SCROLL DOWN ======= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ============ MEDIUM DEVICES =========== */

@media screen and (max-width: 1024px) {
  header {
    height: 75vh;
  }
}

/* ============ SMALL DEVICES =========== */

@media screen and (max-width: 600px) {
  header {
    height: 103vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}

@media screen and (max-height: 878px) {
  header {
    height: 60vh;
  }
  .me {
    display: none;
  }
}
