.container.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  width: 30%;
  padding: .75rem .75rem 0 .75rem;
  padding-bottom: 4rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 35px;
  background: #262626;
  box-shadow:  7px 7px 14px #202020,
  -7px -7px 14px #2c2c2c;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
/*  border-color: var(--color-primary-variant);*/
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

swiper-pagination-clickable swiper-pagination-bullet {
  background: var(--color-primary);
}
/* ============ MEDIUM DEVICES =========== */

@media screen and (max-width: 1024px) {
  .container.portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    width: 70%;
  }
}

/* ============ SMALL DEVICES =========== */

@media screen and (max-width: 600px) {
  .container.portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 90%;
  }
}
